.terms {
	&-hero {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 400px;
		padding: 80px 0;
		@include lg {
			min-height: 300px; }
		@include md {
			min-height: 250px; }
		@include sm {
			padding: 60px 0;
			min-height: 200px; }
		h1 {
			margin: 0; }
		&:before {
			content: '';
			background-image: url("../images/terms-bg.png");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center bottom;
			position: absolute;
			z-index: 1;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0; }
		&__content {
			position: relative;
			z-index: 2;
			margin-top: 40px;
			@include sm {
				margin-top: 20px; } } }
	&-box {
		margin-bottom: 40px;
		&:last-child {
			margin-bottom: 0; } }
	&-content {
		color: #4D4D4D;
		font-size: 15px;
		line-height: 29px;
		letter-spacing: 0;
		padding: 50px 0 150px;
		@include md {
			padding: 40px 0 100px; }
		@include sm {
			padding: 40px 0 80px; }
		table {
			border-collapse: collapse;
			margin-bottom: 50px;
			@include sm {
				font-size: 14px;
				line-height: 1.4; }
			&:last-child {
				margin-bottom: 0; }
			thead {
				font-size: 18px;
				font-family: $sf-sb;
				@include sm {
					font-size: 16px; } }
			td {
				padding: 15px 5px; }
			tr {
				border-bottom: 1px solid rgba(0, 0, 0, .3); } }
		a {
			color: $primary;
			text-decoration: underline;
			&.blue {
				color: #1B4EBB; } }
		h2 {
			text-transform: uppercase;
			font-size: 16px;
			letter-spacing: 3.2px;
			color: $primary;
			font-family: $sf-sb;
			margin-bottom: 33px;
			&:last-child {
				margin-bottom: 0; } }
		h4 {
			font-size: 19px;
			font-family: $sf-sb;
			margin-bottom: 15px; }
		h5 {
			text-transform: uppercase;
			font-family: $sf-sb;
			color: $primary;
			margin-bottom: 33px;
			&:last-child {
				margin-bottom: 0; }
			&.dark {
				color: #2e2e2e; } }
		p {
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0; } }
		ol {
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0; }
			li {
				font-family: $sf-sb;
				margin-bottom: 29px;
				&:last-child {
					margin-bottom: 0; } } }
		ul {
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0; }
			li {
				font-family: $sf-sb; } }
		.gray {
			color: #8C8C8C; }
		&__container {
			max-width: 945px; } }
	&-items {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-bottom: -20px; }
	&-item {
		width: 33.333%;
		padding-right: 20px;
		margin-bottom: 20px;
		@include sm {
			width: 100%;
			padding-right: 0; } } }
