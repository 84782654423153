.about {
    &-items {
        display: flex;
        flex-wrap: wrap;
        margin: -35px -75px;
        @include md {
            margin: -25px -15px; }
        @include xs {
            margin: 0; } }
    &-item {
        width: calc(50% - 150px);
        margin: 35px 75px;
        text-align: left;
        @include md {
            width: calc(50% - 30px);
            margin: 25px 15px; }
        @include xs {
            width: 100%;
            margin: 0 0 30px;
            &:last-child {
                margin-bottom: 0; } }
        &:nth-child(odd) {
            .about-item__inner {
                transition-delay: .2s;
                @include xs {
                    transition-delay: 0s; } } }
        &:nth-child(even) {
            .about-item__inner {
                transition-delay: .5s;
                @include xs {
                    transition-delay: 0s; } } }
        &.active {
            .about-item__inner {
                transform: translateY(0);
                opacity: 1; } }
        &__inner {
            transform: translateY(100%);
            opacity: 0;
            transition: 1s ease;
            @include xs {
                transition: .7s ease; } }
        &__icon {
            width: 54px;
            height: 54px;
            margin-bottom: 16px;
            background: #fff;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            @include xs {
                width: 40px;
                height: 40px; }
            .icon {
                width: 20px;
                height: 20px;
                fill: $primary;
                @include xs {
                    width: 16px;
                    height: 16px; } } }
        &__title {
            color: #000; }
        &__text {
            color: #717171; } } }

.about {
    &-container {
        max-width: 1020px;
        position: relative;
        z-index: 2; }
    &-title {
        font-family: $sr-sb;
        font-size: 40px;
        letter-spacing: 0;
        color: $title;
        line-height: 1.4;
        margin-bottom: 75px;
        @include sm {
            margin-bottom: 40px;
            font-size: 30px; } }
    &-subtitle {
        font-family: $sf-sb;
        text-transform: uppercase;
        font-size: 16px;
        color: $primary;
        letter-spacing: 3.2px;
        margin-bottom: 20px;
        @include md {
            font-size: 14px;
            margin-bottom: 15px; } }
    &-hero {
        position: relative;
        padding-bottom: 36.5%;
        background-color: #FFFCFC;
        @include r(1440px) {
            padding-bottom: 200px; }
        @include sm {
            padding-bottom: 0;
            display: flex;
            flex-direction: column-reverse; }
        .container {
            position: absolute;
            z-index: 2;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            padding-top: 100px;
            @include r(1440px) {
                position: relative; }
            @include sm {
                padding-top: 80px; }
            @include r($xs) {
                padding-top: 60px; } }
        &__title {
            line-height: 1.2;
            @include fluidFontSize(28px, 36px, $xs, $lg); }
        &__content {
            width: 50%;
            max-width: 510px;
            margin: 65px 20px 0 0;
            @include md {
                max-width: 660px;
                margin: 30px auto 0;
                width: 100%; } }
        &__bg {
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            @include r(1440px) {
                height: 300px;
                top: inherit; }
            @include sm {
                position: relative;
                height: auto;
                margin-top: -20%; }
            picture {
                width: 100%;
                height: 100%;
                display: block;
                @include sm {
                    width: 160%;
                    height: auto; } }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: left bottom; } } }
    &-info {
        padding: 65px 0 195px;
        position: relative;
        @include md {
            padding: 65px 0 150px; }
        @include sm {
            padding: 45px 0 100px; }
        &:after {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: url("../images/about-info-bg1.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center bottom; }
        .container {
            position: relative;
            z-index: 2; }
        &__inner {
            width: 100%;
            max-width: 660px;
            margin: 0 auto;
            position: relative;
            &:before {
                content: '';
                background-image: url("../images/about-info-bg2.png");
                background-repeat: no-repeat;
                background-size: contain;
                width: 40px;
                height: 30px;
                position: absolute;
                right: calc(100% + 25px);
                top: 1px; } }
        &__title {
            position: relative;
            color: $primary;
            &:before {
                content: '—';
                position: absolute;
                right: calc(100% + 8px);
                top: 0;
                @include md {
                    position: static;
                    margin-right: 8px; } } }
        &__descr {
            margin-top: 40px;
            @include md {
                margin-top: 30px; } }
        p {
            font-size: 18px;
            line-height: 1.6;
            margin-bottom: 30px;
            color: #8C8C8C;
            @include md {
                font-size: 16px;
                margin-bottom: 20px; }
            &:last-child {
                margin-bottom: 0; } } }
    &-team {
        text-align: center;
        padding: 45px 0 200px;
        position: relative;
        @include md {
            padding: 45px 0 150px; }
        @include sm {
            padding: 45px 0 80px; }
        &:after {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: url("../images/about-team-bg.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center bottom; }
        &__items {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -18px -64px;
            @include sm {
                margin: 0 -12px -40px; } }
        &__item {
            width: calc(25% - 36px);
            margin: 0 18px 64px;
            text-align: left;
            &:hover {
                .about-team__item-img img {
                    filter: grayscale(0); } }
            @include md {
                width: calc(33.3333% - 36px); }
            @include sm {
                width: calc(50% - 24px);
                margin: 0 12px 40px; }
            @include r(450px) {
                width: calc(100% - 24px); }
            &-img {
                margin-bottom: 13px;
                img {
                    filter: grayscale(1);
                    transition: $tr;
                    @include sm {
                        filter: none; } } }
            &-name {
                font-family: $sr-sb;
                font-size: 20px;
                color: #282828;
                letter-spacing: 0;
                @include sm {
                    font-size: 18px; } }
            &-post {
                display: inline-flex;
                color: $primary;
                font-family: $sf-sb;
                font-size: 14px;
                background-image: linear-gradient(to right,#ff4920 30%,rgba(255,255,255,0) 20%);
                background-position: bottom;
                background-size: 8px 1px;
                background-repeat: repeat-x;
                padding-bottom: 5px; } } }
    &-press {
        text-align: center;
        padding: 15px 0 80px;
        @include md {
            padding-bottom: 60px; }
        @include sm {
            padding-bottom: 40px; }
        .about-title {
            margin-bottom: 50px; }
        &__content {
            max-width: 890px;
            margin: 0 auto; }
        &__items {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -34px 28px;
            @include sm {
                margin: 0 -20px 20px; }
            @include r(550px) {
                margin: 0 0 40px; } }
        &__item {
            width: calc(50% - 68px);
            margin: 0 34px 48px;
            background: #FFFFFF;
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.08);
            border-radius: 9px;
            overflow: hidden;
            text-align: left;
            text-decoration: none;
            transition: $tr;
            @include sm {
                width: calc(50% - 40px);
                margin: 0 20px 40px; }
            @include r(550px) {
                width: 100%;
                margin: 0 0 40px;
                &:last-child {
                    margin-bottom: 0; } }
            &:hover {
                box-shadow: 0 10px 15px 0 rgba(0,0,0,0.08);
                transform: translateY(-5px);
                .about-press__item-text {
                    color: $primary; } }
            &-img {
                padding-bottom: 53%;
                position: relative;
                display: block;
                border-radius: 9px;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center; } }
            &-content {
                display: block;
                padding: 15px 34px 34px;
                @include sm {
                    padding: 15px 24px; } }
            &-date {
                font-size: 14px;
                color: #919191;
                letter-spacing: 0;
                display: block;
                margin-bottom: 23px; }
            &-text {
                font-family: $sr-sb;
                font-size: 16px;
                color: #0E0E0E;
                letter-spacing: 0;
                display: block;
                transition: $tr; } } }
    &-partners {
        padding: 80px 0 175px;
        text-align: center;
        @include md {
            padding: 60px 0 100px; }
        .about-title {
            margin-bottom: 60px; }
        &__items {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            margin-bottom: -15px; }
        &__item {
            width: 33.3333%;
            margin-bottom: 15px;
            @include md {
                padding: 0 20px; }
            @include sm {
                width: 50%;
                padding: 0 10px; } } } }
