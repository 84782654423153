
*, *:before, *:after {
    box-sizing: border-box;
    min-height: 0.001vw; }

html {
    overflow-x: hidden;
    &.overflow {
        overflow: hidden; } }

body {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    background: $bg;
    color: $text;
    font-family: $sf-r, Arial, sans-serif;
    width: 100%;
    height: 100%;
    @include fluidFontSize(14px, 16px, $xs, $lg);
    min-height: 100vh;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

img, svg {
    max-width: 100%;
    max-height: 100%; }

input, textarea {
    -webkit-appearance: none;
    border-radius: 0; }

.main {
    flex-grow: 1; }

.container {
    max-width: 1030px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    &-lg {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        padding: 0 15px; } }

h1, h2, h3, h4, h5, h6 {
    font-family: $sf-sb;
    color: $title; }
h1 {
    @include fluidFontSize(27px, 56px, $xs, $lg);
    font-family: $sf-b;
    line-height: 1.2;
    margin-bottom: 30px;
    @include sm {
        margin-bottom: 20px; } }
h2 {
    @include fluidFontSize(26px, 34px, $xs, $lg);
    font-family: $sf-b;
    line-height: 1.5;
    margin-bottom: 40px;
    @include sm {
        margin-bottom: 20px; } }
h3 {
    @include fluidFontSize(20px, 24px, $xs, $lg);
    line-height: 1.2;
    margin-bottom: 25px;
    @include sm {
        margin-bottom: 20px; } }
h4 {
    @include fluidFontSize(18px, 20px, $xs, $lg);
    line-height: 1.4;
    margin-bottom: 20px;
    @include sm {
        margin-bottom: 15px; } }
h5 {
    @include fluidFontSize(16px, 18px, $xs, $lg);
    line-height: 1.5;
    margin-bottom: 20px;
    @include sm {
        margin-bottom: 15px; } }
h6 {
    @include fluidFontSize(14px, 16px, $xs, $lg);
    line-height: 1.5;
    margin-bottom: 20px;
    @include sm {
        margin-bottom: 15px; } }

p {
    line-height: 1.5;
    margin-bottom: 40px;
    &:last-child {
        margin-bottom: 0; } }

a {
    transition: $tr; }

a:not([class]) {
    color: $primary;
    text-decoration: 0;
    border-bottom: 1px solid transparent;
    padding-bottom: .08em;
    &:hover {
        border-bottom: 1px solid $primary; } }

ul:not([class]) {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0; }
    li {
        position: relative;
        line-height: 1.5;
        padding-left: 20px;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0; }
        &:before {
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $primary;
            position: absolute;
            left: 0;
            top: 0.55em; }
        ul, ol {
            margin-top: 10px; } } }

ol:not([class]) {
    margin-bottom: 20px;
    counter-reset: listItem;
    &:last-child {
        margin-bottom: 0; }
    li {
        position: relative;
        line-height: 1.5;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0; }
        &:before {
            counter-increment: listItem;
            content: counter(listItem)'. ';
            color: $primary;
            margin-right: 5px; }
        ol {
            li:before {
                content: counters(listItem, '.') ' '; } }
        ul, ol {
            margin-top: 10px;
            padding-left: 20px; } } }

.section {
    padding: 130px 0;
    @include lg {
        padding: 100px 0; }
    @include md {
        padding: 80px 0; }
    @include sm {
        padding: 60px 0; }
    &--center {
        text-align: center; }
    &--gradient {
        background: linear-gradient(104.35deg, #FFF5F5 0%, rgba(240, 240, 240, 0.514139) 37.33%, rgba(239, 255, 235, 0.334871) 77.61%, rgba(255, 218, 184, 0) 100%);
        .section-subtitle {
            background-color: #fff; } }
    &-subtitle {
        background: #FFF9F8;
        border-radius: 8px;
        padding: 9px 20px;
        font-family: $sr-sb;
        color: $primary;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        @include fluidFontSize(16px, 17px, $xs, $lg);
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0; }
        @include sm {
            margin-bottom: 20px; } }
    &-head {
        margin-bottom: 60px; } }

.typewrite {
    margin-right: 0.8em;
    position: relative;
    color: $primary;
    white-space: nowrap;
    display: inline-block!important;
    &:after {
        content: '';
        width: .525em;
        display: inline-block;
        position: absolute;
        top: 10%;
        margin-left: .05em;
        height: 80%;
        left: 100%;
        background-color: $primary;
        animation: blinky .8s ease alternate infinite; } }


.globe {
    width: 100%;
    height: 1000px;
    background-color: gray; }

@keyframes blinky {
    0% {
        background-color: transparent; }
    100% {
        background-color: $primary; } }
