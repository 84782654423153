.platform {
    &-container {
        display: flex;
        @include sm {
            flex-direction: column-reverse; } }
    &-content {
        width: 50%;
        padding-right: 15px;
        @include sm {
            width: 100%;
            padding: 0; }
        &__inner {
            max-width: 410px;
            @include sm {
                max-width: 100%; } } }
    &-info {
        p {
            @include fluidFontSize(16px, 18px, $xs, $lg); } }
    &-media {
        padding-left: 15px;
        width: 50%;
        @include sm {
            padding-left: 0;
            width: 100%;
            margin-bottom: 30px; }
        &__anime {
            @include sm {
                max-width: 400px; }
            &.active {
                .platform-media {
                    &__logo {
                        opacity: 1; }
                    &__card {
                        &-1, &-2, &-3, &-4 {
                            transform: translateX(0);
                            opacity: 1; } }
                    &__bottom, &__top {
                        transform: translateY(0);
                        opacity: 1; } } } }
        &__bottom {
            margin-top: 4%;
            display: flex;
            align-items: center;
            opacity: 0;
            transition: 1s ease;
            transition-delay: 2.4s;
            transform: translateY(100%);
            @include sm {
                transition: .7s ease;
                transition-delay: 1.8s; }
            img {
                width: 100%; } }
        &__top {
            margin-bottom: 4%;
            display: flex;
            align-items: center;
            opacity: 0;
            transition: 1s ease;
            transition-delay: 2s;
            transform: translateY(-100%);
            @include sm {
                transition: .7s ease;
                transition-delay: 1.5s; }
            img {
                width: 100%; } }
        &__main {
            position: relative;
            width: 100%;
            padding-bottom: 85%; }
        &__logo {
            width: 40%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            align-items: center;
            transition: 1s ease;
            opacity: 0;
            @include sm {
                transition: .7s ease; }
            img {
                width: 100%; } }
        &__card {
            &-1, &-2, &-3, &-4 {
                position: absolute;
                display: flex;
                align-items: center;
                width: 48%;
                opacity: 0;
                transition: 1s ease;
                @include sm {
                    transition: .7s ease; }
                img {
                    width: 100%; } }
            &-1 {
                left: 0;
                top: 0;
                transform: translateX(-100%);
                transition-delay: .4s;
                @include sm {
                    transition-delay: .3s; } }
            &-2 {
                right: 0;
                top: 0;
                transform: translateX(100%);
                transition-delay: .8s;
                @include sm {
                    transition-delay: .6s; } }
            &-3 {
                left: 0;
                bottom: 0;
                transform: translateX(-100%);
                transition-delay: 1.2s;
                @include sm {
                    transition-delay: .9s; } }
            &-4 {
                right: 0;
                bottom: 0;
                transform: translateX(100%);
                transition-delay: 1.6s;
                @include sm {
                    transition-delay: 1.2s; } } } } }
