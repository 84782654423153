.pricing {
    &-items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -30px 60px;
        @include md {
            margin: 0 -15px 40px; }
        @include sm {
            margin: 0 0 30px; } }
    &-item {
        width: calc(50% - 60px);
        margin: 0 30px;
        background: rgba(255, 255, 255, 0.72);
        border: 1px solid #DEDEDE;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.04);
        border-radius: 16px;
        overflow: hidden;
        transition: $tr;
        &:hover {
            background-color: #FFF8F6;
            .pricing-item {
                &__tariff {
                    background-color: #FFF8F6;
                    &-icon {
                        .icon {
                            fill: $primary;
                            &--stroke {
                                stroke: $primary;
                                fill: none; } } }
                    &-name {
                        color: $primary; } } } }
        @include md {
            width: calc(50% - 30px);
            margin: 0 15px; }
        @include sm {
            width: 100%;
            margin: 0 0 30px;
            &:last-child {
                margin-bottom: 0; } }
        &__tariff {
            height: 84px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.07);
            transition: $tr;
            @include md {
                height: 60px; }
            &-icon {
                width: 26px;
                height: 26px;
                margin-right: 10px;
                .icon {
                    fill: #000;
                    transition: $tr;
                    &--stroke {
                        stroke: #000;
                        fill: none; } } }
            &-name {
                font-family: $sf-sb;
                color: #000;
                letter-spacing: 0.185em;
                text-transform: uppercase;
                transition: $tr; } }
        &__content {
            padding: 25px 40px;
            text-align: left;
            @include md {
                padding: 20px 15px; } }
        &__info {
            margin-bottom: 40px;
            padding-bottom: 40px;
            border-bottom: 1px solid rgba(159, 159, 159, .1);
            @include md {
                margin-bottom: 25px;
                padding-bottom: 25px; } }
        &__text {
            color: #575757; }
        &__features {
            margin-bottom: 60px;
            @include md {
                margin-bottom: 40px; }
            &:last-child {
                margin-bottom: 0; } }
        &__feature {
            margin-bottom: 25px;
            display: flex;
            align-items: center;
            @include md {
                margin-bottom: 15px; }
            &:last-child {
                margin-bottom: 0; }
            &-icon {
                width: 26px;
                height: 26px;
                border-radius: 50%;
                background-color: #28AA6B;
                margin-right: 17px;
                display: flex;
                align-items: center;
                justify-content: center;
                .icon {
                    width: 13px;
                    height: 9px; } }
            &-text {
                width: calc(100% - 43px);
                color: #171717; } }
        &__characteristics {
            display: flex;
            margin: -15px;
            @include xs {
                flex-wrap: wrap;
                margin: -10px; } }
        &__characteristic {
            margin: 15px;
            @include xs {
                width: calc(50% - 20px);
                margin: 10px; }
            &-value {
                color: #28AA6B;
                @include fluidFontSize(17px, 20px, $xs, $lg);
                line-height: 1.2;
                margin-bottom: 8px;
                font-family: $sf-sb;
                &--line {
                    text-decoration: line-through; } }
            &-prop {
                @include fluidFontSize(13px, 14px, $xs, $lg);
                color: #848484;
                line-height: 1.2; } } }
    &-footer {
        display: flex;
        align-items: center;
        justify-content: center; }
    &-link {
        display: inline-flex;
        align-items: center;
        margin-left: 14px;
        text-decoration: none;
        color: $text;
        &__img {
            margin-right: 10px; }
        &__icon {
            margin-left: 9px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #fff;
            border: 1px solid #e1e1e1;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
                width: 5px;
                color: #898989; } } } }
