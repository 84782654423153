.hero {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(104.35deg, #E3F2FF 0%, #F8F8F8 30.73%, #FFDCE2 72.4%, #FFDEC0 100%); }
    &>div {
        position: relative;
        z-index: 2; }
    &-container {
        display: flex;
        flex-wrap: wrap;
        padding: 130px 0 180px;
        @include lg {
            padding: 120px 0; }
        @include md {
            padding: 90px 0; }
        @include sm {
            padding: 60px 0; } }
    &-content {
        width: 50%;
        padding-right: 15px;
        @include sm {
            width: 100%;
            padding: 0;
            margin-bottom: 50px;
            text-align: center; } }
    &-descr {
        color: #858585;
        @include fluidFontSize(18px, 22px, $xs, $lg);
        max-width: 430px;
        margin-bottom: 70px;
        span {
            white-space: nowrap;
            display: block; }
        @include sm {
            margin: 0 auto 40px; } }
    &-btns {
        display: inline-flex;
        align-items: center; }
    &-btn {
        margin-right: 40px;
        &:last-child {
            margin-right: 0; } }
    &-card {
        width: 50%;
        padding-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include sm {
            width: 100%;
            padding: 0; }
        &__inner {
            max-width: 480px;
            width: 100%; } }
    &-title {
        span {
            white-space: nowrap;
            display: block; } } }

.api {
    &-hero {
        &__card {
            display: flex;
            padding-bottom: 63%;
            opacity: 0;
            transform: translateX(calc(50vh + 100%));
            transform-style: preserve-3d;
            transition: 1s ease;
            &.active {
                transform: translateX(0);
                opacity: 1; }
            &.anime {
                .api-hero {
                    &__card-inner {
                        transform: rotateX(0.4682rad) rotateY(0.1821rad); }
                    &__code {
                        opacity: 1;
                        transition-delay: 0s; } } }
            &.code {
                .api-hero {
                    &__card-inner {
                        animation: apiCardRotation 15s infinite linear; } } }
            &.flip {
                transform: rotateY(180deg);
                transition-delay: .5s;
                .api-hero {
                    &__card {
                        &-chip {
                            opacity: 1; } }
                    &__code {
                        opacity: 0;
                        transition-delay: 0s; } } }
            &.waiting {
                .api-hero {
                    &__code {
                        opacity: 0; } } }
            &-chip {
                width: 73px;
                height: 51px;
                bottom: 25%;
                left: 15%;
                position: absolute;
                z-index: 4;
                opacity: 0;
                transform: translateZ(-9px);
                transition: 1s ease 1s;
                @include xs {
                    width: 40px; }
                img {
                    width: 100%; } }
            &-inner {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                transform-style: preserve-3d;
                transform-origin: 50% 50%;
                transition: 1s ease 1s;
                box-shadow: 8px 16px 50px rgba(44, 44, 44, 0.2);
                border-radius: 16px;
                .thickness {
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 16px;
                    position: absolute;
                    z-index: 2;
                    background: linear-gradient(#f3f2f2,#ababab);
                    transform: translateZ(-1px);
                    overflow: hidden;
                    &:before {
                        content: '';
                        height: 1px;
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background: linear-gradient(270deg, #ababab, #f3f2f2); }
                    &:nth-child(2) {
                        transform: translateZ(-2px); }
                    &:nth-child(3) {
                        transform: translateZ(-3px); }
                    &:nth-child(4) {
                        transform: translateZ(-4px); }
                    &:nth-child(5) {
                        transform: translateZ(-5px); }
                    &:nth-child(6) {
                        transform: translateZ(-6px); }
                    &:nth-child(7) {
                        transform: translateZ(-7px); } } }
            &-block {
                transform-style: preserve-3d;
                background-color: #E8E7E7;
                border-radius: 16px;
                position: absolute;
                z-index: 3;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                transition: 1s ease; }
            &-back {
                transform: translateZ(-8px);
                background-color: #E8E7E7;
                border-radius: 16px;
                position: absolute;
                z-index: 2;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                transition: 1s ease; } }
        &__code {
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 3%;
            overflow: hidden;
            opacity: 0;
            font-family: $r-r;
            transition: .5s ease 1s;
            pre {
                position: absolute;
                min-height: 100%;
                bottom: 0;
                z-index: 2;
                left: 0;
                line-height: 1.8;
                white-space: pre;
                @include fluidFontSize(12px, 15px, $xs, $lg);
                padding: 10px 15px;
                color: #000;
                @include xs {
                    padding: 5px 10px; } }
            &-hide {
                display: none; }
            &-comment {
                color: #525f7f;
                margin-right: 5px;
                @include r($sm) {
                    margin-right: 2px; } }
            &-value {
                color: #fdbc72; }
            &-string {
                color: #74e4a2; } } } }

@keyframes apiCardRotation {
    0% {
        transform: rotateX(0.4682rad) rotateY(0.1821rad); }
    6% {
        transform: rotateX(0.3rad) rotateY(0.1rad); }
    12% {
        transform: rotateX(0.2rad) rotateY(0.0rad); }
    18% {
        transform: rotateX(0.1rad) rotateY(-0.1rad); }
    24% {
        transform: rotateX(0rad) rotateY(-0.2rad); }
    30% {
        transform: rotateX(-0.1rad) rotateY(-0.3rad); }
    36% {
        transform: rotateX(-0.2rad) rrotateY(-0.2rad); }
    42% {
        transform: rotateX(-0.1rad) rotateY(-0.1rad); }
    48% {
        transform: rotateX(0rad) rotateY(0rad); }
    54% {
        transform: rotateX(0.1rad) rotateY(0.1rad); }
    60% {
        transform: rotateX(0.2rad) rotateY(0.2rad); }
    66% {
        transform: rotateX(0.1rad) rotateY(0.3rad); }
    72% {
        transform: rotateX(-0.1rad) rotateY(0.2rad); }
    78% {
        transform: rotateX(-0.2rad) rrotateY(0.1rad); }
    84% {
        transform: rotateX(-0rad) rotateY(0rad); }
    90% {
        transform: rotateX(0.2rad) rrotateY(-0.1rad); }
    95% {
        transform: rotateX(0.3rad) rotateY(0.1rad); }
    100% {
        transform: rotateX(0.4682rad) rotateY(0.1821rad); } }
