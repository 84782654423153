.radios {
    display: flex;
    margin: 0 -10px;
    .radio {
        width: calc(100% - 20px);
        margin: 0 10px; } }
.radio {
    &-input {
        display: none;
        &:checked {
            &+.radio-content {
                background-color: #FFF9F8;
                border-color: #FFF9F8;
                .radio {
                    &-icon {
                        .icon {
                            fill: $primary;
                            &--stroke {
                                fill: none;
                                stroke: $primary; } } }
                    &-text {
                        color: $primary; } } } } }
    &-content {
        height: 55px;
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #DADADA;
        border-radius: 5px;
        transition: $tr;
        @include sm {
            height: 45px; }
        &:hover {
            background-color: #FFF9F8;
            border-color: #FFF9F8; } }
    &-icon {
        width: 20px;
        height: 20px;
        flex: 0 0 20px;
        margin-right: 9px;
        .icon {
            fill: #9C9C9C;
            transition: $tr;
            &--stroke {
                fill: none;
                stroke: #9C9C9C; } } }
    &-text {
        font-family: $sf-sb;
        @include fluidFontSize(11px, 12px, $xs, $lg);
        letter-spacing: 0.185em;
        text-transform: uppercase;
        color: #9C9C9C;
        transition: $tr; } }
