.error-404 {
	padding: 80px 0;
	&-image {
		padding: 80px 0 0;
		img {
			margin: auto;
			display: block; } }
	&-text {
		margin: 10px 0 80px;
		h3 {
			margin: 0;
			padding: 0;
			font-size: 30px;
			font-weight: 600;
			line-height: 40px;
			color: #666666;
			text-align: center; } }
	&-button {
		text-align: center;
		a {
			padding: 15px 40px 15px 40px;
			box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.5);
			border-radius: 30px 30px 30px 30px;
			border-width: 0px 0px 0px 0px;
			border-style: solid;
			border-color: #313131;
			background-color: #d82d34;
			font-size: 15px;
			font-weight: 500;
			color: #ffffff; } } }
