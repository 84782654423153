.clients {
    &-items {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        @include md {
            margin-top: 40px; }
        @include sm {
            margin: 0; }
        @include xs {
            margin: 0 -15px; } }
    &-item {
        width: 50%;
        padding: 0 120px;
        text-align: left;
        border-right: 1px solid #F7F7F7;
        &:lat-child {
            border: 0; }
        @include md {
            padding: 0 60px; }
        @include sm {
            padding: 0 30px; }
        @include xs {
            padding: 0 15px; }
        &:nth-child(odd) {
            .clients-item__inner {
                transition-delay: .2s;
                @include xs {
                    transition-delay: 0s; } } }
        &:nth-child(even) {
            .clients-item__inner {
                transition-delay: .5s;
                @include xs {
                    transition-delay: 0s; } } }
        &.active {
            .clients-item__inner {
                transform: translateY(0);
                opacity: 1; } }
        &__inner {
            transform: translateY(100%);
            opacity: 0;
            transition: 1s ease;
            @include xs {
                transition: .7s ease; } }
        &__media {
            height: 50px;
            margin-bottom: 25px;
            @include sm {
                height: 40px;
                margin-bottom: 15px; } }
        &__text {
            @include fluidFontSize(16px, 22px, $xs, $lg); }
        &__content {
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid #F4F4F4;
            @include sm {
                margin-bottom: 20px;
                padding: 0;
                border: 0; } }
        &__person {
            display: flex;
            align-items: center;
            &-avatar {
                margin-right: 20px;
                width: 54px;
                height: 54px;
                border-radius: 50%;
                object-fit: cover;
                object-position: center; }
            &-info {
                width: calc(100% - 74px); }
            &-name {
                font-family: $sf-sb;
                @include fluidFontSize(18px, 20px, $xs, $lg); }
            &-post {
                color: #A3A3A3;
                @include fluidFontSize(15px, 17px, $xs, $lg); } } } }
