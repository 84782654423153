.footer {
    background-color: #232323;
    color: #fff;
    &-main {
        padding: 70px 0 120px;
        @include sm {
            padding: 60px 0 80px; }
        @include xs {
            padding: 40px 0 60px; } }
    &-bottom {
        padding: 24px 0;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50px;
            right: 50px;
            height: 1px;
            mix-blend-mode: normal;
            opacity: 0.16;
            background-color: #525252;
            @include lg {
                left: 15px;
                right: 15px; } }
        &__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include sm {
                flex-wrap: wrap; } } }
    &-copyright {
        color: #343434;
        @include fluidFontSize(13px, 14px, $xs, $lg);
        @include sm {
            width: 100%;
            order: 3;
            text-align: center;
            margin-top: 10px; } }
    &-logo {
        &__link {
            display: flex;
            width: 102px;
            &:hover {
                .icon {
                    fill: $primary; } }
            .icon {
                fill: #8C8C8C;
                transition: $tr; } } }
    &-social {
        &__list {
            display: flex;
            align-items: center; }
        &__item {
            margin-right: 34px;
            @include sm {
                margin-right: 20px; }
            &:last-child {
                margin-right: 0; } }
        &__link {
            &:hover {
                .icon {
                    fill: $primary; } }
            .icon {
                width: 18px;
                height: 18px;
                fill: #8C8C8C;
                transition: $tr; } } }
    &-menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 -15px;
        @include xs {
            margin: 0 -15px -20px; }
        &__block {
            margin: 0 15px;
            @include sm {
                width: calc(33.333% - 30px); }
            @include xs {
                width: calc(50% - 30px);
                margin: 0 15px 20px;
                &:last-child {
                    margin-bottom: 0;
                    width: 100%;
                    .footer-menu__list {
                        column-count: 2;
                        column-gap: 30px; } } } }
        &__title {
            display: inline-flex;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-bottom: 25px;
            font-family: $sf-b;
            @include fluidFontSize(11px, 12px, $xs, $lg);
            border-bottom: 1px dashed #888888;
            padding-bottom: 5px;
            @include xs {
                margin-bottom: 15px; } }
        &__list {
            column-count: 2;
            column-gap: 50px;
            @include md {
                column-gap: 15px; }
            @include sm {
                column-count: 1; } }
        &__item {
            margin-bottom: 12px;
            @include xs {
                margin-bottom: 8px; } }
        &__link {
            display: inline-flex;
            text-decoration: none;
            @include fluidFontSize(14px, 15px, $xs, $lg);
            color: #696969;
            &:hover {
                color: $primary; } } } }
