.dev {
    &-container {
        display: flex;
        flex-wrap: wrap; }
    &-content {
        width: 50%;
        padding-right: 15px;
        @include sm {
            width: 100%;
            padding: 0;
            margin-bottom: 40px; }
        &__inner {
            max-width: 380px; } }
    &-descr {
        @include fluidFontSize(17px, 20px, $xs, $lg);
        margin-bottom: 40px; }
    &-media {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 15px;
        @include sm {
            width: 100%;
            padding: 0; }
        &__card {
            max-width: 430px;
            width: 100%;
            height: 100%;
            position: relative; } }
    &-card {
        width: 100%;
        padding-bottom: 100%;
        position: relative;
        opacity: 0;
        transform: translateX(calc(50vh + 100%));
        transform-style: preserve-3d;
        transition: 1s ease;
        &.active {
            opacity: 1;
            transform: translateX(0);
            .dev-card__shield {
                transform: translateY(0);
                opacity: 1; } }
        &.anime {
            .dev {
                &-card__inner {
 }                    // transform: rotateX(-0.3604rad) rotateY(0.3555rad)
                &-code {
                    opacity: 1;
                    transition-delay: 0s; } } }
        &.restart {
            .dev {
                &-code {
                    opacity: 0;
                    transition-delay: 0s; } } }
        &.code {
            animation: devCardMove 6s infinite linear;
            .dev-card__shield {
                animation: devShieldMove 8s infinite linear; } }
        &__btns {
            width: 15%;
            position: absolute;
            z-index: 5;
            top: 6%;
            right: 17%;
            display: flex;
            align-items: center; }
        &__shield {
            width: 25%;
            position: absolute;
            display: flex;
            align-items: center;
            right: -10%;
            bottom: -10%;
            opacity: 0;
            transform: translateY(100%);
            transition: 1s ease 1s; }
        &__inner {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: url('../images/dev-code-box.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            transition: 1s ease 1s;
 } }            // overflow: hidden
    &-code {
        width: calc(100% - 50px);
        height: calc(100% - 50px);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 3%;
        overflow: hidden;
        opacity: 0;
        font-family: $r-r;
        transform: rotate(-7deg);
        transition: .5s ease 1s;
        pre {
            position: absolute;
            min-height: 100%;
            bottom: 0;
            z-index: 2;
            left: 0;
            line-height: 1.8;
            white-space: pre;
            @include fluidFontSize(12px, 15px, $xs, $lg);
            padding: 25px 15px;
            color: #000;
            @include xs {
                padding: 15px 10px; } }
        &__value {
            color: #fdbc72; }
        &__string {
            color: #74e4a2; }
        &__hide {
            display: none; }
        &__comment {
            color: #525f7f;
            margin-right: 5px;
            @include r($sm) {
                margin-right: 2px; } }
        // &__value
        //     color: #74e4a2
        //     &--bool
        //         color: #AE67BF
        //     &--num
 } }        //         color: #fdbc72

@keyframes devCardMove {
    0% {
        transform: translateY(0); }
    50% {
        transform: translateY(-8%); }
    100% {
        transform: translateY(0); } }

@keyframes devShieldMove {
    0% {
        transform: translateY(0); }
    50% {
        transform: translateY(-24%); }
    100% {
        transform: translateY(0); } }
