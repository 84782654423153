.select2-container--default .select2-selection--single {
    border: 1px solid #DADADA;
    border-radius: 5px; }
.select2-container .select2-selection--single {
    height: 55px;
    @include sm {
        height: 45px; } }
.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 55px;
    padding: 0 30px;
    @include sm {
        line-height: 45px;
        padding: 0 15px; } }
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 20px;
    width: 10px;
    height: 6px;
    background-image: url('../images/select-arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    b {
        display: none; } }
.select2-container--open {
    .select2-selection__arrow {
        transform: rotate(180deg); } }
.select2-dropdown {
    border: 1px solid #DADADA; }
.select2-container--default .select2-results__option--selected {
    background-color: #DADADA; }
.select2-results__option {
    padding: 6px 30px;
    transition: $tr;
    @include sm {
        padding: 6px 15px; } }
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: $primary; }
