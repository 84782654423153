.contact {
    &-container {
        display: flex;
        padding: 0 60px;
        @include lg {
            padding: 0; }
        @include sm {
            display: block; } }
    &-content {
        width: 50%;
        padding-right: 15px;
        @include sm {
            width: 100%;
            padding: 0;
            margin-bottom: 50px; }
        &__inner {
            max-width: 360px;
            @include sm {
                max-width: 100%; } } }
    &-form {
        width: 50%;
        padding-left: 15px;
        @include sm {
            width: 100%;
            padding: 0; }
        form {
            max-width: 370px;
            @include sm {
                max-width: 100%; } } }
    &-descr {
        @include fluidFontSize(17px, 20px, $xs, $lg);
        color: #474747; }
    &-row {
        margin-bottom: 25px;
        @include sm {
            margin-bottom: 15px; }
        &:last-child {
            margin-bottom: 0; } }
    &-submit {
        text-align: right;
        @include sm {
            text-align: left; }
        .btn {
            height: 53px;
            padding: 9px 40px;
            @include sm {
                height: 45px; } } } }
