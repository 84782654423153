.field {
    &-text {
        color: #171717;
        line-height: 1.2;
        margin-bottom: 10px;
        display: block; }
    &-input, &-textarea {
        border-radius: 5px;
        border: 1px solid #DADADA;
        width: 100%;
        outline: 0;
        @include fluidFontSize(14px, 16px, $xs, $lg);
        font-family: $sf-r; }
    &-input {
        height: 55px;
        padding: 0 30px;
        @include sm {
            height: 45px;
            padding: 0 15px; } }
    &-textarea {
        height: 140px;
        resize: none;
        padding: 17px 30px;
        @include sm {
            height: 100px;
            padding: 10px 15px; } } }
