.btn {
    padding: 9px 20px;
    border-radius: 8px;
    line-height: 1.2;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: $sf-m;
    border: 0;
    outline: 0;
    cursor: pointer;
    @include fluidFontSize(14px, 15px, $xs, $lg);
    height: 40px;
    transition: $tr;
    &--main {
        color: #fff;
        background-color: $primary;
        &:hover {
            background-color: $title; } }
    &--white {
        background-color: #fff;
        color: $text2;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
        &:hover {
            background-color: $text2;
            color: #fff; } }
    &-arrow {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        color: $text2;
        &:hover {
            .btn-arrow__icon {
                transform: translateX(5px); } }
        &__text {
            margin-right: 15px;
            font-family: $sf-m;
            @include fluidFontSize(14px, 15px, $xs, $lg); }
        &__icon {
            width: 38px;
            height: 38px;
            background-color: $primary;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $tr;
            .icon {
                fill: #fff;
                width: 7px;
                height: 12px; } } } }

.btn {
    &-fill, &-border {
        height: 38px;
        display: inline-flex;
        align-items: center;
        background: #f8f8f8;
        border-radius: 11px;
        font-size: 14px;
        color: $title;
        position: relative;
        padding: 0 40px 0 20px;
        text-decoration: none;
        &:hover {
            opacity: 0.8; }
        &:after {
            content: '';
            background-image: url("../images/arrow.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 5px;
            height: 10px;
            position: absolute;
            right: 18px;
            top: 1px;
            bottom: 0;
            margin: auto; } }
    &-border {
        background: #fff;
        border: 1px solid #f8f8f8;
        &:hover {
            background-color: #f8f8f8; } } }
