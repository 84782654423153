.header {
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
    position: relative;
    z-index: 5;
    &-container {
        max-width: 1250px;
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
    &-drop {
        @include md {
            display: none;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            background-color: #fff;
            -webkit-overflow-scrolling: touch;
            overflow-y: auto;
            text-align: center;
            padding: 90px 0 50px;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06); } }
    &-burger {
        display: none;
        @include md {
            display: block;
            position: relative;
            z-index: 2;
            width: 24px;
            height: 20px;
            span {
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 2px;
                background-color: $primary;
                transition: $tr;
                &:nth-child(1) {
                    top: 0; }
                &:nth-child(2) {
                    top: 0;
                    bottom: 0;
                    margin: auto; }
                &:nth-child(3) {
                    bottom: 0; } }
            &.active {
                span {
                    &:nth-child(1) {
                        transform: rotate(45deg) translate(7px, 6px); }
                    &:nth-child(2) {
                        transform: translateX(-10px);
                        opacity: 0; }
                    &:nth-child(3) {
                        transform: rotate(-45deg) translate(7px, -6px); } } } } }
    &-menu {
        display: flex;
        align-items: center;
        @include md {
            display: block; }
        &__list {
            display: flex;
            align-items: center;
            margin-right: 40px;
            @include md {
                display: block;
                margin: 0 0 30px; } }
        &__item {
            margin-right: 60px;
            @include lg {
                margin-right: 40px; }
            @include md {
                margin: 0 0 10px; }
            &:last-child {
                margin-right: 0; } }
        &__link {
            color: $text2;
            font-family: $sf-m;
            text-decoration: none;
            @include fluidFontSize(14px, 15px, $xs, $lg);
            &:hover {
                color: $primary; } } }
    &-logo {
        position: relative;
        z-index: 2;
        &__link {
            display: flex; }
        &__img {
            width: 145px; } } }
