.bitcoin {
    &-container {
        display: flex;
        justify-content: center;
        padding: 0 20px;
        flex-direction: column;
        align-items: center;
        background-image: linear-gradient(to right, rgba(255, 244, 244, 1), #fafffa); }
    &-header {
        margin: 118px 0 90px;
        &-top {
            display: flex;
            justify-content: center;
            margin-bottom: 17px;
            &-dollar {
                font-size: 45.5px;
                font-weight: bold;
                margin-top: 20px; }
            &-value {
                font-size: 76px;
                font-weight: bold; } }
        &-bottom {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 42px;
                height: 42px; }
            span {
                font-size: 35.5px;
                margin-left: 17px;
                font-weight: 900; } } }
    &-desc {
        max-width: 486px;
        &-title {
            font-size: 19.5px;
            font-weight: 900;
            margin-bottom: 35px; }
        &-list {
            &-item {
                display: flex;
                margin-bottom: 50px;
                &-number {
                    border-radius: 50%;
                    font-size: 16px;
                    font-weight: 500;
                    background: yellow;
                    color: #ff3800;
                    width: 28px;
                    min-width: 28px;
                    height: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 17px;
                    background: white; }
                &-text {
                    font-size: 19.5px;
                    font-weight: 500;
                    a {
                        color: #ff3800;
                        font-weight: bold;
                        text-decoration: none; }
                    span {
                        color: #ff3800;
                        font-weight: bold; } } } } }
    &-form {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            display: block;
            max-width: 100%;
            height: 100%;
            @include lg {
                display: none; } }
        &-panel {
            width: 486px;
            padding: 60px 44px 46px;
            margin: 0 45px;
            margin-bottom: 70px;
            object-fit: contain;
            border-radius: 6px;
            box-shadow: 0px 4px 13px 0 rgba(124, 124, 124, 0.13);
            border: solid 1px #e4e4e4;
            background-color: #ffffff;
            @include md {
                width: 100%;
                margin: 0; }
            &-row {
                margin-bottom: 90px;
                @include sm {
                    margin-bottom: 60px; }
                &:last-child {
                    margin-bottom: 50px; } } }
        &-button {
            display: flex;
            justify-content: center; } }
    &-footer {
        max-width: 486px;
        margin-top: 50px;
        &-title {
            font-size: 19.5px;
            font-weight: 900;
            margin-bottom: 34px; }
        &-desc {
            font-size: 13.5px;
            font-weight: 500;
            margin-bottom: 140px; } } }

