.scale {
    position: relative;
    padding: 270px 0;
    @include lg {
        padding: 130px 0; }
    @include md {
        padding: 100px 0; }
    @include sm {
        padding: 60px 0; }
    &-content {
        width: 70%;
        @include sm {
            width: 60%; }
        @include xs {
            width: 100%; } }
    &-info {
        max-width: 400px;
        margin-bottom: 70px;
        @include sm {
            margin-bottom: 50px; }
        @include sm {
            margin-bottom: 30px; } }
    &-globe {
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        transition: 1s ease;
        cursor: grab;
        &.active {
            opacity: 1; }
        @include sm {
            max-width: 50%; }
        @include xs {
            display: none; }
        path {
            transition: 1s ease; } }
    &-items {}
    &-descr {
        color: #9F9F9F;
        @include fluidFontSize(17px, 20px, $xs, $lg); }
    &-item {
        padding-left: 20px;
        margin-bottom: 40px;
        &:last-child {
            margin: 0; }
        &__value {
            position: relative;
            font-family: $sf-m;
            @include fluidFontSize(24px, 28px, $xs, $lg);
            color: $primary;
            margin-bottom: 6px;
            @include sm {
                margin: 0; }
            &:before {
                content: '';
                position: absolute;
                left: -20px;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 1px;
                height: 24px;
                background-color: $primary;
                @include sm {
                    height: 18px;
                    left: -15px; } } }
        &__text {
            @include fluidFontSize(15px, 17px, $xs, $lg); } } }
